<template>
  <div class="internet">
    <Head></Head>
    <div class="in-content">
      <div class="top">
        <div class="top-title">
          <img src="@/image/internet/top/internet-title.jpg" alt="" />
        </div>
        <div class="meiti">
          <img src="@/image/internet/internet-2.jpg" alt="" />
        </div>
      </div>
      <div style="width: 100%; background: #e9e9ef">
        <div class="video">
          <div class="video-item">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer0"
              :playsinline="true"
              :options="playerOptions0"
            >
            </video-player>
          </div>
          <div class="video-tips">
            <p>
              中央电视台新闻频道《新闻1+1》做出专题报道，进一步引起全国广泛关注
            </p>
          </div>
        </div>
      </div>

      <div>
        <img src="@/image/internet/internet-3.jpg" alt="" />
      </div>
      <div class="video-box-bag">
        <img src="@/image/contentplat/tuijian.jpg" alt="" />
      </div>
      <div class="viedo-box">
        <div class="contentplat-video" style="width: 100%; height: 100%">
          <video-player
            :video-url="videoPath"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="false"
            :options="playerOptions"
          >
          </video-player>
        </div>
      </div>

      <div style="display: flex; padding: 2vh 0vw; margin: 0 auto; width: 70%">
        <div
          class="viedo-imgs"
          v-for="(item, index) in videoImage"
          :key="index"
        >
          <img :src="item.src" alt="" @click="switchVideo(item)" />
        </div>
      </div>
      <Foot></Foot>
    </div>
    <!-- <div class="article">
      <div>
        <img src="@/image/internet/internet-3.jpg" />
      </div>
      <div
        class="article-item"
        v-for="(item, index) in article_list"
        :key="index"
      >
        <a href="javascript:void(0)" @click="goDetailURl(item.url)">
          <img :src="item.src" />
        </a>
      </div>
    </div>
    <div class="news">
      <div class="news-item" v-for="(item, index) in news_list" :key="index">
        <div>
          <a
            style="color: #333"
            href="javascript:void(0)"
            @click="goDetailURl(item.child[0].url)"
            >{{ item.child[0].title }}</a
          >
        </div>
        <span style="color:#ccc;font-size: 35px;font-weight: 200">丨</span>
        <div>
          <a
            style="color: #333"
            href="javascript:void(0)"
            @click="goDetailURl(item.child[1].url)"
            >{{ item.child[1].title }}</a
          >
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "internet",

  data() {
    return {
      videoPath: "",
      videoImage: [
        {
          src: require("@/image/contentplat/videoImage/longnan.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E9%99%87%E5%8D%97.mp4",
        },

        {
          src: require("@/image/contentplat/videoImage/lanzhou.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E5%85%B0%E5%B7%9E.mp4",
        },
        {
          src: require("@/image/contentplat/videoImage/jiuquan.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E9%85%92%E6%B3%89.mp4",
        },
        {
          src: require("@/image/contentplat/videoImage/zhangye.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E5%BC%A0%E6%8E%96.mp4",
        },
        {
          src: require("@/image/contentplat/videoImage/onetime.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E7%94%98%E8%82%83%E4%B8%80%E5%88%86%E9%92%9F.mp4",
        },
      ],
      center_media: [
        { src: require("@/image/internet/top/logo/宣传_04.jpg"), url: "" },
        {
          src: require("@/image/internet/top/logo/宣传_06.jpg"),
          url: [
            { src: require("@/image/mediapage/新华/新华网_01.jpg") },
            { src: require("@/image/mediapage/新华/新华网_02.jpg") },
            { src: require("@/image/mediapage/新华/新华网_03.jpg") },
            { src: require("@/image/mediapage/新华/新华网_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/zhongguolvyou.jpg"),
          url: [
            { src: require("@/image/mediapage/中国旅游报/中国旅游报_01.jpg") },
            { src: require("@/image/mediapage/中国旅游报/中国旅游报_02.jpg") },
          ],
        },
      ],
      province_media: [
        {
          src: require("@/image/internet/top/logo/dushi.jpg"),
          url: [
            {
              src: require("@/image/mediapage/甘肃都市调频/甘肃都市调频_01.jpg"),
            },
            {
              src: require("@/image/mediapage/甘肃都市调频/甘肃都市调频_02.jpg"),
            },
            {
              src: require("@/image/mediapage/甘肃都市调频/甘肃都市调频_03.jpg"),
            },
          ],
        },
        {
          src: require("@/image/internet/top/logo/jingjiguangbo.jpg"),
          url: [
            {
              src: require("@/image/mediapage/甘肃经济广播/甘肃经济广播_01.jpg"),
            },
            {
              src: require("@/image/mediapage/甘肃经济广播/甘肃经济广播_02.jpg"),
            },
          ],
        },
        {
          src: require("@/image/internet/top/logo/jiaotongguangbo.jpg"),
          url: [
            {
              src: require("@/image/mediapage/甘肃交通广播/甘肃交通广播_01.jpg"),
            },
            {
              src: require("@/image/mediapage/甘肃交通广播/甘肃交通广播_02.jpg"),
            },
            {
              src: require("@/image/mediapage/甘肃交通广播/甘肃交通广播_03.jpg"),
            },
          ],
        },
      ],
      other_media: [
        { src: require("@/image/internet/top/logo/宣传_116.jpg"), type: "1" },
        {
          src: require("@/image/internet/top/logo/宣传_118.jpg"),
          url: [
            { src: require("@/image/mediapage/马蜂窝/马蜂窝_01.jpg") },
            { src: require("@/image/mediapage/马蜂窝/马蜂窝_02.jpg") },
            { src: require("@/image/mediapage/马蜂窝/马蜂窝_03.jpg") },
            { src: require("@/image/mediapage/马蜂窝/马蜂窝_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_120.jpg"),
          url: [
            {
              src: require("@/image/mediapage/报道新闻网/中国报道新闻网_01.jpg"),
            },
            {
              src: require("@/image/mediapage/报道新闻网/中国报道新闻网_02.jpg"),
            },
            {
              src: require("@/image/mediapage/报道新闻网/中国报道新闻网_03.jpg"),
            },
            {
              src: require("@/image/mediapage/报道新闻网/中国报道新闻网_04.jpg"),
            },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_122.jpg"),
          url: [
            { src: require("@/image/mediapage/丝路文博网/丝路文博网_01.jpg") },
            { src: require("@/image/mediapage/丝路文博网/丝路文博网_02.jpg") },
            { src: require("@/image/mediapage/丝路文博网/丝路文博网_03.jpg") },
            { src: require("@/image/mediapage/丝路文博网/丝路文博网_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_124.jpg"),
          url: [
            { src: require("@/image/mediapage/网易新闻/网易新闻_01.jpg") },
            { src: require("@/image/mediapage/网易新闻/网易新闻_02.jpg") },
            { src: require("@/image/mediapage/网易新闻/网易新闻_03.jpg") },
            { src: require("@/image/mediapage/网易新闻/网易新闻_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_148.jpg"),
          url: [
            { src: require("@/image/mediapage/乐途/乐途_01.jpg") },
            { src: require("@/image/mediapage/乐途/乐途_02.jpg") },
            { src: require("@/image/mediapage/乐途/乐途_03.jpg") },
            { src: require("@/image/mediapage/乐途/乐途_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_150.jpg"),
          url: [
            { src: require("@/image/mediapage/途牛/途牛_01.jpg") },
            { src: require("@/image/mediapage/途牛/途牛_02.jpg") },
            { src: require("@/image/mediapage/途牛/途牛_03.jpg") },
            { src: require("@/image/mediapage/途牛/途牛_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_152.jpg"),
          url: [
            { src: require("@/image/mediapage/中国搜索/中国搜索_01.jpg") },
            { src: require("@/image/mediapage/中国搜索/中国搜索_02.jpg") },
            { src: require("@/image/mediapage/中国搜索/中国搜索_03.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_154.jpg"),
          url: [
            { src: require("@/image/mediapage/爱奇艺/爱奇艺_01.jpg") },
            { src: require("@/image/mediapage/爱奇艺/爱奇艺_02.jpg") },
            { src: require("@/image/mediapage/爱奇艺/爱奇艺_03.jpg") },
            { src: require("@/image/mediapage/爱奇艺/爱奇艺_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_156.jpg"),
          url: [
            { src: require("@/image/mediapage/今日头条/今日头条_01.jpg") },
            { src: require("@/image/mediapage/今日头条/今日头条_02.jpg") },
            { src: require("@/image/mediapage/今日头条/今日头条_03.jpg") },
            { src: require("@/image/mediapage/今日头条/今日头条_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_180.jpg"),
          url: [
            { src: require("@/image/mediapage/腾讯/腾讯_01.jpg") },
            { src: require("@/image/mediapage/腾讯/腾讯_02.jpg") },
            { src: require("@/image/mediapage/腾讯/腾讯_03.jpg") },
            { src: require("@/image/mediapage/腾讯/腾讯_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_182.jpg"),
          url: [
            { src: require("@/image/mediapage/美团/美团_01.jpg") },
            { src: require("@/image/mediapage/美团/美团_02.jpg") },
            { src: require("@/image/mediapage/美团/美团_03.jpg") },
            { src: require("@/image/mediapage/美团/美团_04.jpg") },
            { src: require("@/image/mediapage/美团/美团_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_184.jpg"),
          url: [
            { src: require("@/image/mediapage/百度-360/百度---360_01.jpg") },
            { src: require("@/image/mediapage/百度-360/百度---360_02.jpg") },
            { src: require("@/image/mediapage/百度-360/百度---360_03.jpg") },
            { src: require("@/image/mediapage/百度-360/百度---360_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_186.jpg"),
          url: [
            { src: require("@/image/mediapage/携程/携程_01.jpg") },
            { src: require("@/image/mediapage/携程/携程_02.jpg") },
            { src: require("@/image/mediapage/携程/携程_03.jpg") },
            { src: require("@/image/mediapage/携程/携程_04.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_188.jpg"),
          url: [
            { src: require("@/image/mediapage/外网/外网_01.jpg") },
            { src: require("@/image/mediapage/外网/外网_02.jpg") },
            { src: require("@/image/mediapage/外网/外网_03.jpg") },
          ],
        },
        {
          src: require("@/image/internet/top/logo/宣传_212.jpg"),
          url: [
            { src: require("@/image/mediapage/百度-360/百度---360_01.jpg") },
            { src: require("@/image/mediapage/百度-360/百度---360_02.jpg") },
            { src: require("@/image/mediapage/百度-360/百度---360_03.jpg") },
            { src: require("@/image/mediapage/百度-360/百度---360_04.jpg") },
          ],
        },
        { src: require("@/image/internet/top/logo/宣传_214.jpg"), url: "" },
        {
          src: require("@/image/internet/top/logo/宣传_216.jpg"),
          url: [
            { src: require("@/image/mediapage/甘肃文旅/甘肃文旅_01.jpg") },
            { src: require("@/image/mediapage/甘肃文旅/甘肃文旅_02.jpg") },
            { src: require("@/image/mediapage/甘肃文旅/甘肃文旅_03.jpg") },
            { src: require("@/image/mediapage/甘肃文旅/甘肃文旅_04.jpg") },
          ],
        },
        // {src:require("@/image/internet/top/logo/facebook.jpg"),url:""},
        // {src:require("@/image/internet/top/logo/xiecheng.jpg"),url:""}
      ],

      news_list: [
        {
          child: [
            {
              title: "全国唯一名字里带甜的省份，真的太甜了！",
              url: "https://mp.weixin.qq.com/s/1hFVyiEadFuneBA-fJSC8Q",
            },
            {
              title: "公示！甘肃9地拟入选全国乡村旅游重点村镇！",
              url: "https://mp.weixin.qq.com/s/-r5AD85bDh8wJDviOIlEYw",
            },
            // {
            //     title:'“如意甘肃·一马当先” ——甘肃彩车闪耀首都、祝福祖国',
            //     url:'https://mp.weixin.qq.com/s?__biz=MjM5NzA1Nzg3OQ==&mid=2650520972&idx=2&sn=18e0d475f1c160cc2589846118e64ad8&chksm=bed0d9b489a750a20fa6990c733ddc8ae5f82744b392fdeebb929bfbca861aa0ed256467de59&token=1228876550&lang=zh_CN#rd'
            // },
            // {
            //     title:'“一会一节”印象：人民的节日、简单的快乐',
            //     url:'https://mp.weixin.qq.com/s?__biz=MjM5NzA1Nzg3OQ==&mid=2650518875&idx=1&sn=4016da33bcd6b595346dca6d32c1e14d&chksm=bed0d16389a75875976da149fc98dec65cc5c41ad1994ed2fb3c5cf06a38e616cff8a5b13b15&token=1228876550&lang=zh_CN#rd'
            // },
          ],
        },
        {
          child: [
            {
              title: "河西走廊，为什么能决定中原王朝千年的兴衰？",
              url: "https://mp.weixin.qq.com/s/MsDApDPKx54a0z46-4RbFQ",
            },
            {
              title: "用《骁》的方式打开甘肃",
              url: "https://mp.weixin.qq.com/s/k7_4HoJwGhcHab12xVhUrA",
            },
            // {
            //     title:'黄轩代言甘肃旅游形象宣传片，完整版抢先看',
            //     url:'https://www.ixigua.com/i6618443406764966413/'
            // },
            // {
            //     title:'甘肃张掖，有多少极致风光？',
            //     url:'https://mp.weixin.qq.com/s/MPiMtHAQS1G34ZLixDHdvg'
            // }
          ],
        },
        {
          child: [
            {
              title: "看一眼就心动！扎尕那，今夏神仙避暑地！",
              url: "https://mp.weixin.qq.com/s/FtnFdvPR-FRQQdPblOoZxQ",
            },
            {
              title: "兰州牛肉面，凭什么是“天下第一面”？",
              url: "https://mp.weixin.qq.com/s/oDQL3prIchSKPUtlkEoguw",
            },
            // {
            //     title:'甘肃最后的绝对秘境，是九寨沟的10倍美！',
            //     url:'https://mp.weixin.qq.com/s/O3t1wqGZtN1NTrnQtLUIqQ'
            // },
            // {
            //     title:'甘肃从哪里来？将往何处去？',
            //     url:'https://mp.weixin.qq.com/s/Z4h5coOThK1b7lXcgvkPbw'
            // }
          ],
        },
        {
          child: [
            {
              title: "在河西走廊，你能看到什么？",
              url: "https://mp.weixin.qq.com/s/jbatdNZKTTBt2dgdujseng",
            },
            {
              title: "最甘肃的甘肃，到底在哪里？",
              url: "https://mp.weixin.qq.com/s/HHTyOIuoL6u5RsIlwc9rjA",
            },
            // {
            //     title:'24小时，逛吃逛吃游兰州',
            //     url:'https://mp.weixin.qq.com/s/W0MKAt-yrQwrXOhGHj92jQ'
            // },
            // {
            //     title:'甘肃如此多娇',
            //     url:'https://mp.weixin.qq.com/s/brwcnOjwBriXljUCipnUIw'
            // }
          ],
        },
        {
          child: [
            {
              title: "甘肃：有一种沧桑之变叫“如意”",
              url: "http://m.xinhuanet.com/gs/2021-06/02/c_1127522231.htm",
            },
            {
              title: "山河故事 | 武威，一马跃古今 一窟传千年",
              url: "http://m.xinhuanet.com/gs/2021-06/03/c_1127525293.htm",
            },
            // {
            //     title:'为什么甘肃天水很不甘肃？',
            //     url:'https://mp.weixin.qq.com/s/Dh8w802A-67RTV52n7Kx1Q'
            // },
            // {
            //     title:'甘肃，不只有牛肉面',
            //     url:'https://mp.weixin.qq.com/s/Mv8cxnnmCiW0udqxTNoDLA'
            // }
          ],
        },
        {
          child: [
            {
              title: "代表甘肃的美食，不是牛肉面？",
              url: "https://mp.weixin.qq.com/s/vxIOgvwJssvso9PAlsIlrg",
            },
            {
              title: "你好，这里是甘肃——如此河山",
              url: "https://mp.weixin.qq.com/s/ymjPm4yHLNrfUccwwyqILQ",
            },
            // {
            //     title:'敦煌，为何能有千年“沙泉共生”的奇迹？',
            //     url:'https://mp.weixin.qq.com/s/cJvLxl2KqVqCi71bOQYVVQ'
            // },
            // {
            //     title:'除过牛肉面，甘肃还有9999种吃面法！',
            //     url:'https://mp.weixin.qq.com/s/oPyf5pqQVajeHmsKEs1VDA'
            // }
          ],
        },
        {
          child: [
            {
              title: "在甘肃，羊肉到底有多少种吃法？",
              url: "https://mp.weixin.qq.com/s/FHVUgBt6ytu-0JvIvQ6ZjQ",
            },
            {
              title: "甘肃面食大比拼，看你最喜欢哪一个？",
              url: "https://mp.weixin.qq.com/s/du7aOCetBeg0THwrjJygXg",
            },
            // {
            //     title:'甘肃的甘，到底从哪里来？',
            //     url:'https://mp.weixin.qq.com/s/XteiqksG7ydTj4IPcO2DRQ'
            // },
            // {
            //     title:'甘肃省会是怎么选出来的？',
            //     url:'https://mp.weixin.qq.com/s/58yKUCZc8-wYysrEdtO9Uw'
            // }
          ],
        },
        {
          child: [
            {
              title: "西北望，如意甘肃在绽放",
              url: "https://mp.weixin.qq.com/s/L3lgvQ85UjUt6zZ6BVcNbA",
            },
            {
              title: "这才是甘肃正确的打开方式！",
              url: "https://mp.weixin.qq.com/s/D39XEprlsqVg_SKPnvFYvQ",
            },
            // {
            //     title:'甘肃，伸展成诗一样的远方',
            //     url:'https://mp.weixin.qq.com/s/qXRgu_-TQEF_bIVvaGRlJw'
            // },
            // {
            //     title:'甘肃丨因为一条路',
            //     url:'https://mp.weixin.qq.com/s/6Mvjj2ed_4hab3r7RKs-dA'
            // }
          ],
        },
        {
          child: [
            {
              title: "为什么不出甘肃，就能游遍大半个中国？",
              url: "https://mp.weixin.qq.com/s/ObOn80yRMXLMJs6XdVZFXg",
            },
            {
              title: "甘肃旅游形象大使黄轩告诉你甘肃到底有什么..",
              url: "https://mp.weixin.qq.com/s/qM7Qah3bVlQK3tt0AdIX-g",
            },
            //   {
            //       title:'甘肃白银 | 难言的壮美，丝绸之路跨越黄河的美丽传说！',
            //       url:'https://mp.weixin.qq.com/s?__biz=MTExNTQ5NDc4MQ==&mid=2656738398&idx=1&sn=8ae60dc24bf53556b90bb51baf348c40&chksm=71ecd24c469b5b5a59e719fbfdc21dd60aabf7762a27e1ae88df496b5803b294cc9577d5f2da&mpshare=1&scene=1&srcid=10160Q4dW8BqbiolqYH8BrQz&sharer_sharetime=1571223033668&sharer_shareid=968be67951e1f2299e85c2e82d6471ef&key=4314b36099b4021c83543365f059dd6a04943b101301179d610055b7a7a0040c29852dcb23cb8d6bfa0960a6a88b485ca5fb7da5302c83a56204596249da91e663d2bd6bac8c9e6c760909b2a2c8a6b8&ascene=1&uin=MzEzNzI4NzU3OQ%3D%3D&devicetype=Windows+10&version=62060841&lang=zh_CN&pass_ticket=isXP8YIWb0LZArDzDGCy0ogkoqSBMVDkME%2BYDgAPQxKutlm7m3EyzTCJvt7AxkZv'
            //   },
            //   {
            //       title:'活着就能见的“天堂”，你一生必须去一次甘南',
            //       url:'https://mp.weixin.qq.com/s/TMVpoMhFt4Qfeih9SMDGlg'
            //   }
          ],
        },
        {
          child: [
            {
              title: "在兰州为什么找不到兰州拉面？",
              url: "https://mp.weixin.qq.com/s/XjuJS4Ey1oz2qcgHHuy67w",
            },
            {
              title: "站着等你三千年：仰望“东亚文化之都”",
              url: "https://mp.weixin.qq.com/s/nSGJveN8_yWJagH7VDBV0A",
            },
            // {
            //     title:'兰州 | 走过这座灿若苍穹的“金城”，你的长假才算完整！',
            //     url:'https://mp.weixin.qq.com/s/ByUY8TAlUn-E0kXVKd_ZAg'
            // },
            // {
            //     title:'为什么我们不懂兰州？',
            //     url:'https://mp.weixin.qq.com/s/WJo4Sw4h018catnd9aUerQ'
            // }
          ],
        },
        {
          child: [
            {
              title: "在兰州，不得不吃的8种特色小吃！",
              url: "https://mp.weixin.qq.com/s/CjXOvcCMcRANVyJfEaoUJg",
            },
            {
              title: "在甘肃只要60秒，就能让你心动！",
              url: "https://mp.weixin.qq.com/s/WvwQ4fxG1Av5WFW6PnFA1w",
            },
            // {
            //     title:'我和我的兰州',
            //     url:'https://mp.weixin.qq.com/s/7anpfXsHgErJKK4d51r1Kg'
            // },
            // {
            //     title:'兰州至敦煌的动车开通！一路向西北，原来竟这么美！',
            //     url:'https://mp.weixin.qq.com/s/40WuRUsTV_e2d4NhV2O4wA'
            // }
          ],
        },
        {
          child: [
            {
              title: "甘肃有一条绝美自驾旅游公路，出发！",
              url: "https://mp.weixin.qq.com/s/wEBk9PIZZvOuLR0xyhi2DA",
            },
            {
              title: "红色沃土滋养甘肃文旅结硕果",
              url: "https://mp.weixin.qq.com/s/dAZ2YxLIci4bnjK7x3wJcg",
            },
            // {
            //     title:'黄轩：心定的地方，才是故乡',
            //     url:'https://mp.weixin.qq.com/s/JoknAycFelp37HEb4lvtRw'
            // },
            // {
            //     title:'甘肃旅游必去的40个地方',
            //     url:'http://www.sohu.com/a/250079577_810494'
            // }
          ],
        },
        {
          child: [
            {
              title: "“天祝，远比你想象中的更动人！",
              url: "https://mp.weixin.qq.com/s/a5TgwHapwu2psWNTnnzo6g",
            },
            {
              title: "甘肃：有一种沧桑之变叫“如意”",
              url: "https://mp.weixin.qq.com/s/20USjqaC7W_TLxQNOlQYQg",
            },
            // {
            //     title:'“一会一节”直击敦煌：有一个“圈”用敦煌命名',
            //     url:'https://article.xuexi.cn/articles/index.html?art_id=15899169025678005298&study_style_id=feeds_default&source=share&share_to=wx_single&from=timeline&isappinstalled=0'
            // },
            // {
            //     title:'甘肃各大景区的朋友圈沸腾了！',
            //     url:'https://mp.weixin.qq.com/s?__biz=MjM5MDc2NTg0MA==&mid=2693951030&idx=1&sn=dcd96ad8668dd1bd0139322d854b153f&chksm=83d90849b4ae815fa3a85fd5fc7fd638addfcdf5897231193fa2457ad4748c5beb83c7edfa3b&mpshare=1&scene=1&srcid=0502DwuWF84BBC7Gn6VYSIKY&sharer_sharetime=1571231926657&sharer_shareid=3e772da7fb5697a24ce5fc1f055e8226&key=c677575ee01da766e3caac443baa4129352db4683db84fe46a1c4fd9b7ff866a2c12ddb008d9285f55e7ed5837f1b75f40afce57644365bfe726d60ad0c44eab58a6dd067e25315792923064d1632f4c&ascene=1&uin=MjcyNjM1MzI2MA%3D%3D&devicetype=Windows+7&version=62070141&lang=zh_CN&pass_ticket=HzZn3IU37wpCSafUuvZFN6WZdVYl7nejFT1rEcg3dHqc90GAXJYwd%2BtVZfSiEp6J'
            // }
          ],
        },
        {
          child: [
            {
              title: "六月，奔赴甘南，去见证它究竟有多美！",
              url: "https://mp.weixin.qq.com/s/1DEo3oLf-EVS6ijozTww8Q",
            },
            {
              title: "毕业旅行，你最想为考生推荐甘肃哪里？",
              url: "https://mp.weixin.qq.com/s/v5aCYPaFb8i_FN8cjwlXEQ",
            },
          ],
        },
        {
          child: [
            {
              title: "甘肃：“古老而厚重，多样而美好”",
              url: "https://mp.weixin.qq.com/s/BYqAC46KL5OR1YYr-grECQ",
            },
            {
              title: "炎炎夏日，甘肃人都在吃什么？",
              url: "https://mp.weixin.qq.com/s/TrTNNziiem33cG-KjWzlRg",
            },
          ],
        },
        {
          child: [
            {
              title: "甘南，到底美在哪里？",
              url: "https://mp.weixin.qq.com/s/RTMgP2oKKQ-egJ5xMDE-yA",
            },
            {
              title: "在河西走廊，你能看到什么？",
              url: "https://mp.weixin.qq.com/s/jbatdNZKTTBt2dgdujseng",
            },
          ],
        },
      ],
      article_list: [
        {
          src: require("@/image/internet/精美文章/4_02.png"),
          url: "https://mp.weixin.qq.com/s/h9k6xn_a-Lqrf39s3arnzw",
        },
        // {
        //     src:require('@/image/internet/精美文章/4_04.png'),
        //     url:'https://mp.weixin.qq.com/s/Ihx-GzQTIYVlIHplrFLECA'
        // },
        {
          src: require("@/image/internet/精美文章/4_03.png"),
          url: "https://mp.weixin.qq.com/s/w3zidvu6GpLPlOw5ZcXxmg",
        },
        {
          src: require("@/image/internet/精美文章/4_04.png"),
          url: "https://mp.weixin.qq.com/s/wuSvXWfrdcCgPnKK9QjEQA",
        },
        {
          src: require("@/image/internet/精美文章/4_05.png"),
          url: "https://mp.weixin.qq.com/s/Qzy6g03DryDmMgiDCzq-Fg",
        },
        {
          src: require("@/image/internet/精美文章/4_06.png"),
          url: "https://mp.weixin.qq.com/s/eMCEzgUhsO5Nqx_AdXWJ1Q",
        },
        {
          src: require("@/image/internet/精美文章/4_07.jpg"),
          url: "https://mp.weixin.qq.com/s/VnrfqXU5ZPZgZ0lYOsk9Cg",
        },
        {
          src: require("@/image/internet/精美文章/4_08.jpg"),
          url: "https://mp.weixin.qq.com/s/8ouseBD93b0bQH0DiIjKfw",
        },
        {
          src: require("@/image/internet/精美文章/4_09.jpg"),
          url: "https://mp.weixin.qq.com/s/62MMPJT33xmkKEersLsbyw",
        },
      ],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E9%99%87%E5%8D%97.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/contentplat/videoImage/longnan.jpg"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      playerOptions0: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/anli/%E6%96%B0%E9%97%BB1%2B1.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/internet/video-pre.jpg"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  components: { Head, Foot },

  methods: {
    goDetail: function (url) {
      this.$router.push("/iframe?url=" + url);
    },
    goDetailURl(url) {
      // this.$router.push('/iframe?url=' + url)
      // window.location.href =url
      window.open(url);
    },
    switchVideo: function (item) {
      this.playerOptions.poster = item.src;
      this.playerOptions.sources[0].src = item.url;
    },
    goTomediaPage: function (url, type) {
      if (type === "1") {
        this.$router.push("/weiyou");
      } else {
        let params = {
          url: url,
        };
        // localStorage.setItem('urls',url)
        this.$router.push({
          path: "/mediapage",
          query: params,
        });
        // window.console.log(url)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.internet {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.in-content {
  width: 100%;
  height: 100%;
  // img {
  //   width: 70%;
  // }
}
.video {
  margin: 0 auto;
  width: 75%;
  padding: 5vh 18vw;
}

.video-tips {
  background: #fff;
  padding: 2vh;
  font-size: 0.9vw;
}

.top-title {
  width: 100%;
}

.viedo-box {
  width: 70%;
  margin: 0 auto;
  padding: 0 10vw;
  margin-top: -25vh;
  img {
    width: 100%;
    height: 100%;
  }
}
.video-player .vjs-big-play-button {
  top: 44% !important;
  left: 48% !important;
  border-radius: 50% !important;
  height: 3em !important;
  border: none !important;
  line-height: 2.8em !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.viedo-imgs img {
  width: 98%;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.viedo-imgs:hover img {
  cursor: pointer;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
</style>
